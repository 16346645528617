import { Redirect } from 'react-router'

import { getStoredPath } from 'util/localRedirectUrlStorage'

const Home = () => {
  const redirectLocation = getStoredPath() && getStoredPath() !== '/' ? getStoredPath() : '/assets'
  return <Redirect to={redirectLocation} />
}

export default Home
